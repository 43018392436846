import * as React from 'react';
import type { NavElement } from '@kiwicom/nitro/lib/components/TopNav';
import TopNav from '@kiwicom/nitro/lib/components/TopNav';
import styled from 'styled-components';
import { useFeature } from '@kiwicom/darwin';
// Values taken from kiwi.com
const Nav = styled.nav`
  z-index: 99;
  position: relative;
`;

export const hideFeatures = (
  isFareLockDisabled: boolean,
  isReferAFriendDisabled: boolean,
): NavElement[] => {
  const FEATURE_NAMES = {
    FARE_LOCK: 'fareLocks', // From TopNav NavElements
    REFER_A_FRIEND: 'referAFriend',
  };

  const hiddenFeatures = [];
  if (isFareLockDisabled) {
    hiddenFeatures.push(FEATURE_NAMES.FARE_LOCK);
  }
  if (isReferAFriendDisabled) {
    hiddenFeatures.push(FEATURE_NAMES.REFER_A_FRIEND);
  }
  return hiddenFeatures;
};

const TopNavComponent = (props: React.ComponentProps<typeof TopNav>) => {
  const isNewRegionalSettingsEnabled = useFeature('NEW_REGIONAL_SETTINGS');
  const isFareLockEnabled = useFeature('FARE_LOCK');
  const isReferAFriendDisabled = useFeature('DISABLE_REFER_A_FRIEND');

  const hiddenFeatures = hideFeatures(!isFareLockEnabled, isReferAFriendDisabled);
  return (
    <Nav>
      <TopNav
        {...props}
        hide={hiddenFeatures}
        isNewRegionalSettings={isNewRegionalSettingsEnabled}
      />
    </Nav>
  );
};
export default TopNavComponent;
